import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Popover,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  Box
} from "@mui/material";
import dayjs from 'dayjs';
import { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAutosave } from "react-autosave";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import {
  OHTdropdownOptions,
  dropdownOptions,
  res_data,
  InteractiondropdownOptions,
} from "../../data";
import { dateFormat, add3dots,fiscalYearFormat, stringDateToFormat } from "../../utils";
import { themeColors, themeTypography } from "../../themes";
import { updateSurveyService } from "../../services";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const StyledSpan = styled("span")({
  position: 'absolute',
  right: '40px'
});

const StyledH5 = styled("h5")({
  ...themeTypography.headings.h5,
  fontWeight: "bold",
  margin: 0,
  paddingTop: 3,
  paddingBottom: 8,
});

const StyledSectionDiv = styled("div")({
  padding: "1rem",
  border: `2px solid ${themeColors.ui.ui_divider_subtle}`,
  borderRadius: "8px",
  marginBottom: "2rem",
});

const RowElement = styled("div")({
  display: "flex",
  marginRight: "1rem",
});

const StyledH5Light = styled("h5")({
  margin: 0,
  paddingRight: "2rem",
});

const SmallText = styled("p")({
  fontSize:"10px"
});

const H5ZeroMargin = styled("h5")({
  margin: 0,
});

const ItalicHeader = styled("h5")({
  ...themeTypography.headings.h5,
  fontWeight: "bold",
  fontStyle: "italic",
  margin: "0 0 0.5rem 0",
  paddingTop: 10,
  paddingBottom: 10,
});

const QuestionHeader = styled("h5")({
  ...themeTypography.headings.h5,
  fontWeight: "bold",
  margin: "0 0 0.5rem 0",
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 15,
  color: themeColors.ui.ui_primary_highlight,
});

const SubHeader1 = styled("h5")({
  margin: 0,
  paddingBottom: 10,
  paddingTop: 10,
});

const SubHeader2 = styled("h5")({
  margin: 0,
  paddingRight: 20,
  paddingBottom: 10,
  paddingTop: 20,
});

const BodyText = styled("span")({
  ...themeTypography.body.body_text_01,
  color: themeColors.text.text_secondary,
  fontSize: "14px",
  fontWeight: "500",
});

function Accordion510k({ survey, delIdFunc510k }) {
  const [filledData, setFilledData] = useState(res_data);
  const [disablePreSub, setDisablePresub] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const [accordionColor, setAccordionColor] = useState(
    themeColors.ui.ui_primary_highlight
  );
  const today = dayjs();
  function updateSurvey(data) {
    if (
      data.date_filed_accepted &&                               // Date Submission was Accepted by the FDA
      // data.date_substantive_interaction &&                   // Date of Substantive Interaction / Additional Information Request by the FDA
      data.substantive_interaction &&                           // What was the outcome of the Substantive Interaction?
      data.breakthrough_designated_product !== null &&          // Did the device have a Breakthrough Device Designation?
      data.OHT &&                                               // Which OHT reviewed the Submission?
      data.patient_generated_data !== null &&                   // Was Patient-Generated Data used during the review process? (e.g. Preferences, Patient Reported Outcomes)
      data.ai_ml_enabled !== null &&                            // Is the device AI/ML-Enabled?
      data.real_world_evidence_used !== null &&                 // Was Real World Evidence used during the review process?
      data.date_first_submitted !== null                        // Date application was first submitted to the FDA
    )
      data.survey_status = "completed";
    else if (
      data.date_filed_accepted ||                               // Date Submission was Accepted by the FDA
      // data.date_substantive_interaction ||                   // Date of Substantive Interaction / Additional Information Request by the FDA
      data.substantive_interaction ||                           // What was the outcome of the Substantive Interaction?
      data.breakthrough_designated_product !== null ||          // Did the device have a Breakthrough Device Designation?
      data.OHT ||                                               // Which OHT reviewed the Submission?
      data.patient_generated_data !== null ||                   // Was Patient-Generated Data used during the review process? (e.g. Preferences, Patient Reported Outcomes)
      data.ai_ml_enabled !== null ||                            // Is the device AI/ML-Enabled?
      data.real_world_evidence_used !== null ||                 // Was Real World Evidence used during the review process?
      data.date_first_submitted !== null                        // Date application was first submitted to the FDA
    )
      data.survey_status = "started";
    else data.survey_status = "empty";

    if (JSON.stringify(data) !== JSON.stringify(res_data))
      updateSurveyService(survey.id, data);
  }
  useAutosave({ data: filledData, onSave: updateSurvey });

  useEffect(() => {
    if (survey) {
      survey.file_pre_submission? setDisablePresub(false) : setDisablePresub(true)
      setFilledData((prevState) => {
        return {
          ...prevState,
          year: survey.year,
          file_pre_submission: survey.file_pre_submission,
          date_requested: survey.dates.pre_submission_process.date_requested,
          meeting_held: survey.dates.pre_submission_process.meeting_held,
          written_feedback:
            survey.dates.pre_submission_process.written_feedback,
          date_filed_accepted:
            survey.dates.pre_submission_process.date_filed_accepted,
          date_substantive_interaction:
            survey.dates.pre_submission_process.date_substantive_interaction,
          date_company_feedback:
            survey.dates.pre_submission_process.date_company_feedback,
          date_approvable_pma:
            survey.dates.pre_submission_process.date_approvable_pma,
          date_advisory_pma:
            survey.dates.pre_submission_process.date_advisory_pma,
          NSE_decision_date:
            survey.dates.pre_submission_process.NSE_decision_date,
          decision: survey.other_details.decision,
          date_first_submitted: survey.dates.pre_submission_process.date_first_submitted,
          real_world_evidence_used:
            survey.other_details.real_world_evidence_used,
          breakthrough_designated_product:
            survey.other_details.breakthrough_designated_product,
          OHT: survey.other_details.OHT,
          device_approved_china: survey.other_details.device_approved_china,
          device_approved_eu: survey.other_details.device_approved_eu,
          device_approved_japan: survey.other_details.device_approved_japan,
          device_submitted_china: survey.other_details.device_submitted_china,
          device_submitted_eu: survey.other_details.device_submitted_eu,
          device_submitted_japan: survey.other_details.device_submitted_japan,
          substantive_interaction: survey.other_details.substantive_interaction,
          patient_generated_data: survey.other_details.patient_generated_data,
          clear_statement: survey.other_details.clear_statement,
          other_notes: survey.other_details.other_notes,
          ai_ml_enabled: survey.other_details.ai_ml_enabled,
          survey_status: survey.survey_status,
          submission_put_on_hold: survey.dates.review_process.submission_put_on_hold,
          date_of_submission_hold: survey.dates.review_process.date_of_submission_hold
        };
      });
    }
  }, []);

  useEffect(() => {
    colorChangeFunc(survey);
  }, [filledData]);

  // ----------------------- helper functions -----------------------

  const colorChangeFunc = (survey) => {
    let dateFieldCount = 0;
    let otherFieldsCount = 0;

    for (let i in survey.dates.pre_submission_process) {
      if (survey.dates.pre_submission_process[i] !== null) {
        dateFieldCount += 1;
      }
    }
    for (let i in survey.other_details) {
      if (i != "other_notes") {
        if (survey.other_details[i] !== null) {
          otherFieldsCount += 1;
        }
      }
    }

    if (localStorage.getItem("alignment") == "true") {
      if (
        filledData.date_filed_accepted &&
        filledData.substantive_interaction &&
        filledData.breakthrough_designated_product !== null &&
        filledData.OHT &&
        filledData.patient_generated_data !== null &&
        filledData.ai_ml_enabled !== null &&
        filledData.real_world_evidence_used !== null &&
        filledData.date_first_submitted != null
      )
        setAccordionColor(themeColors.ui.ui_completed);
      else if (
        filledData.date_filed_accepted ||
        filledData.substantive_interaction ||
        filledData.breakthrough_designated_product||
        filledData.OHT ||
        filledData.patient_generated_data ||
        filledData.ai_ml_enabled ||
        filledData.real_world_evidence_used ||
        filledData.date_first_submitted
      )
        {setAccordionColor(themeColors.ui.ui_semi_complete);}
      else setAccordionColor(themeColors.ui.ui_primary_highlight);
    } else {
      if (dateFieldCount >= 6 && otherFieldsCount >= 15) {
        setAccordionColor(themeColors.ui.ui_completed);
      } else if (dateFieldCount > 1 && otherFieldsCount > 1) {
        setAccordionColor(themeColors.ui.ui_semi_complete);
      } else {
        setAccordionColor(themeColors.ui.ui_primary_highlight);
      }
    }
  };

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const handleGeoSubmitEUDateChange = (date) => {
   
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_submitted_eu: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
   
  };

  const handleGeoSubmitJapanDateChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_submitted_japan: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
   
  };

  const handleGeoSubmitChinaDateChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_submitted_china: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  };

  const handleGeoApprovedEUDateChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_approved_eu: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  };

  const handleGeoApprovedJapanDateChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_approved_japan: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  };

  const handleGeoApprovedChinaDateChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        device_approved_china: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  };

  const handleNullDate = () => {
    setFilledData((prevState) => {
      return {
        ...prevState,
        file_pre_submission: false,
        date_requested: null,
        meeting_held: null,
        written_feedback: null,
      };
    });
  };
  const handleDateReqChange = (date) => {
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        date_requested: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  };
  const handleMeetingHeldChange = (date) => {
  if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        meeting_held: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });
  }
  const handleWrittenFeedbackChange = (date) =>{
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY')))) 
    setFilledData((prevState) => {
      return {
        ...prevState,
        written_feedback: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });}
  const handleDateFiledChange = (date) =>{
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        date_filed_accepted: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });}
  const handleDateSubmittedChange = (date) =>{
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
  setFilledData((prevState) => {
    return {
      ...prevState,
      date_first_submitted: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      year: fiscalYearFormat(date, "YYYY-MM-DD")
    }
  })
  }
  const handleSubstantiveDateChange = (date) =>{
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        date_substantive_interaction: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });}
  const handleCompanyFeedbackDateChange = (date) =>{
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
    setFilledData((prevState) => {
      return {
        ...prevState,
        date_company_feedback: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
      };
    });}
  const handleOHTChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        OHT: e.target.value,
      };
    });

    const handleFilePreSubmissionChange = (e) => {
      setDisablePresub(e === true ? false : true);
      if(e === false) handleNullDate(); 
      else
      setFilledData((prevState) => {
         return {
          ...prevState,
          file_pre_submission: e,
        };
      });
    };
  const handleInteractionChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        substantive_interaction: e.target.value,
      };
    });
  const handleAIEnableChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        ai_ml_enabled: e.target.value,
      };
    });
  const handleRealWorldEvidenceChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        real_world_evidence_used: e.target.value,
      };
    });
  const handlePatientGeneratedDataChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        patient_generated_data: e.target.value,
      };
    });
  const handleBreakthroughDesignatedProductChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        breakthrough_designated_product: e.target.value,
      };
    });
  const handleClearStatementChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        clear_statement: e.target.value,
      };
    });
  const handleOtherNotesChange = (e) =>
    setFilledData((prevState) => {
      return {
        ...prevState,
        other_notes: e.target.value,
      };
    });

  function handleSubmissionHoldChange(e) {
    const stateUpdate = {
      submission_put_on_hold: e.target.value,
    };

    if (e.target.value !== "true") {
      stateUpdate.date_of_submission_hold = null;
    }

    setFilledData((prevState) => {
      return {
        ...prevState,
        ...stateUpdate,
      };
    });
  }

  function handleSubmissionHoldDateChange(date) {
    if (date === null || (date.isValid() && !date.isAfter(moment(new Date(),'MM/DD/YYYY'))))
      setFilledData((prevState) => {
        return {
          ...prevState,
          date_of_submission_hold: date === null ? null : stringDateToFormat(date, "YYYY-MM-DD"),
        };
      });
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Accordion sx={{ marginBottom: "9px", borderRadius: '8px !important' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: themeColors.text.text_inverse }} />
          }
          id="panel1a-header"
          sx={{ background: `${accordionColor} !important`, borderRadius: "8px", }}
        >
          <Typography
            sx={{
              ...themeTypography.headings.h4,
              margin: 0,
              color: themeColors.text.text_inverse,
            }}
            aria-owns={popOverOpen ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {survey.product_details.k_number.toUpperCase()}{" "}
            {survey.product_details.device &&
              " | " + add3dots(survey.product_details.device)}{" "}
            {/* {survey.dates.review_process.date_received &&
              " | " + dateFormat(survey.dates.review_process.date_received)} */}
            <StyledSpan> {
                accordionColor === themeColors.ui.ui_semi_complete ? "Data entry in progress" : ( accordionColor === themeColors.ui.ui_primary_highlight ? "Data entry not started": "All required fields filled")
              }
            </StyledSpan>
          </Typography>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={popOverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>
              {survey.product_details.device}
            </Typography>
          </Popover>
        </AccordionSummary>

        <AccordionDetails>
          {/* ------------------------- Section 1 ------------------------- */}
          <StyledH5>Product details</StyledH5>
          <StyledSectionDiv>
            <Grid container spacing={0.5}>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={3}>
                    <StyledH5>K Number</StyledH5>
                  </Grid>
                  <Grid xs={9}>
                    <BodyText>
                      {survey.product_details.k_number.toUpperCase()}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={4}>
                    <StyledH5>Applicant Name</StyledH5>
                  </Grid>
                  <Grid xs={8}>
                    <BodyText>
                      {survey.product_details.applicant}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={3}>
                    <StyledH5>Type</StyledH5>
                  </Grid>
                  <Grid xs={9}>
                    <BodyText>
                      {survey.product_details.product_type}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={3}>
                    <StyledH5>Device</StyledH5>
                  </Grid>
                  <Grid xs={9}>
                    <BodyText>
                      {survey.product_details.device}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={4}>
                    <StyledH5>Product Code</StyledH5>
                  </Grid>
                  <Grid xs={8}>
                    <BodyText>
                      {survey.product_details.product_code}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
            </Grid>
          </StyledSectionDiv>
          {/* ------------------------- Section 2 ------------------------- */}
          <StyledH5>Dates</StyledH5>
          <StyledSectionDiv>
            <Grid xs={12}>
              <RowElement>
                <Grid xs={6}>
                  <QuestionHeader>
                    Did you file a pre-submission?
                  </QuestionHeader>
                </Grid>
                <Grid xs={6}>
                  <ToggleButtonGroup
                    color="secondary"
                    exclusive
                    aria-label="Platform"
                    size="small"
                    value={filledData.file_pre_submission}
                  >
                    <ToggleButton
                      onClick={() => {
                        handleFilePreSubmissionChange(true);
                      }}
                      value={true}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => {
                        handleFilePreSubmissionChange(false);
                      }}
                      value={false}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </RowElement>
            </Grid>
            <ItalicHeader>Pre-submission process</ItalicHeader>
            <Grid container spacing={2}>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                      Date most recent Pre-submission was requested (if
                      requested)
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        disabled={disablePreSub}
                        value={
                          disablePreSub === false
                            ? filledData.date_requested
                            : null
                        }
                        onChange={(date) => handleDateReqChange(date)}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                      Date most recent Pre-submission Meeting was held (if held)
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        disabled={disablePreSub}
                        value={
                          disablePreSub === false
                            ? filledData.meeting_held
                            : null
                        }
                         
                        // inputFormat="YYYY-MM-DD"
                        onChange={handleMeetingHeldChange}
                        format="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7.45}>
                    <StyledH5Light>
                      Date Written Feedback was issued on the most recent
                      Pre-submission (if issued)
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={4.55}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        disabled={disablePreSub}
                        value={
                          disablePreSub === false
                            ? filledData.written_feedback
                            : null
                        }
                         
                        onChange={handleWrittenFeedbackChange}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
            </Grid>

            <ItalicHeader>Review process</ItalicHeader>
            <Grid container spacing={2}>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                    Date application was first submitted to the FDA
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                      disableFuture={true}
                      onChange={handleDateSubmittedChange}
                      value={filledData.date_first_submitted}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                      Was this submission put on a technical screening hold between submission and acceptance by the FDA?
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="submission_put_on_hold"
                        value={filledData.submission_put_on_hold}
                        onChange={handleSubmissionHoldChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display + "submission_hold"} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
                 
                {[true, "true"].includes(filledData.submission_put_on_hold) ? 
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                      If yes, what was the date of the hold?
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        value={filledData.date_of_submission_hold}
                        onChange={handleSubmissionHoldDateChange}
                        renderInput={(params) => (
                          <TextField 
                            size="small" 
                            {...params} 
                            error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement> : null 
                }

              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <StyledH5Light>
                      Date Submission was Accepted by the FDA
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        value={filledData.date_filed_accepted}
                        onChange={handleDateFiledChange}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7.45}>
                    <StyledH5Light>
                      Date of Substantive Interaction / Additional Information Request by the FDA, if different from Decision Date
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={4.55}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        value={filledData.date_substantive_interaction}
                        onChange={handleSubstantiveDateChange}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <H5ZeroMargin>
                      Date of Company Feedback to Substantive Interaction /
                      Additional Information Request (if applicable)
                    </H5ZeroMargin>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture={true}
                        value={filledData.date_company_feedback}
                        onChange={handleCompanyFeedbackDateChange}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>

              <Grid xs={4}>
                <RowElement>
                  <Grid xs={7}>
                    <SubHeader1>Decision Date</SubHeader1>
                  </Grid>
                  <Grid xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={true}
                         
                        value={dateFormat(
                          survey.dates.review_process.decision_date
                        )}
                        renderInput={(params) => (
                          <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </RowElement>
              </Grid>
            </Grid>
          </StyledSectionDiv>

          {/* ------------------------- Section 3 ------------------------- */}
          <StyledH5>Other details</StyledH5>
          <StyledSectionDiv>
            <Grid container spacing={2}>
              <Grid xs={3}>
                <RowElement>
                  <Grid xs={6}>
                    <SubHeader1>Decision</SubHeader1>
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                  >
                    <TextField
                      disabled
                      rows={1}
                      size="small"
                      defaultValue={survey.other_details.decision}
                      sx={{ width: "88%" }}
                    />
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={8}>
                    <SubHeader1>
                    What was the outcome of the Substantive Interaction? {'('}NOTE: Receiving a decision is considered by FDA to be Substantive Interaction{')'}
                    </SubHeader1>
                  </Grid>
                  <Grid xs={4}>
                    <FormControl
                      sx={{ m: 1, minWidth: 120, maxWidth: 120 }}
                      size="small"
                    >
                      <Select
                        id="OHT-select"
                        value={filledData.substantive_interaction}
                        onChange={handleInteractionChange}
                      >
                        {InteractiondropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={5}>
                <RowElement>
                  <Grid xs={9}>
                    <SubHeader2>
                      Did the device have a Breakthrough Device Designation?
                    </SubHeader2>
                  </Grid>
                  <Grid xs={3}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="breakthrough-designated-select"
                        value={filledData.breakthrough_designated_product}
                        onChange={handleBreakthroughDesignatedProductChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={3}>
                <RowElement>
                  <Grid xs={6}>
                    <SubHeader1>
                      Which OHT reviewed the Submission?
                    </SubHeader1>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl
                      sx={{ m: 1, minWidth: 120, maxWidth: 170 }}
                      size="small"
                    >
                      <Select
                        id="OHT-select"
                        value={filledData.OHT}
                        onChange={handleOHTChange}
                      >
                        {OHTdropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={8}>
                    <H5ZeroMargin>
                      Was Patient-Generated Data used during the review process?
                      (e.g. Preferences, Patient Reported Outcomes)
                    </H5ZeroMargin>
                  </Grid>
                  <Grid xs={4}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="patient-generated-select"
                        value={filledData.patient_generated_data}
                        onChange={handlePatientGeneratedDataChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={5}>
                <RowElement>
                  <Grid xs={9}>
                    <SubHeader1>
                      Was there a specific statement of basis in your Additional
                      Information or Deficiency Letter? (if applicable)
                    </SubHeader1>
                  </Grid>
                  <Grid xs={3}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="clear-statement"
                        value={filledData.clear_statement}
                        onChange={handleClearStatementChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={3}>
                <RowElement>
                  <Grid xs={6}>
                    <SubHeader1>
                      Is the device AI/ML-Enabled?
                    </SubHeader1>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="patient-generated-select"
                        value={filledData.ai_ml_enabled}
                        onChange={handleAIEnableChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={4}>
                <RowElement>
                  <Grid xs={8}>
                    <SubHeader1>
                      Was Real World Evidence used during the review process?
                    </SubHeader1>
                  </Grid>
                  <Grid xs={4}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="real-world-evidence-select"
                        value={filledData.real_world_evidence_used}
                        onChange={handleRealWorldEvidenceChange}
                      >
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option.display} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={5}>
                <RowElement>
                  <Grid xs={10}>
                    <SubHeader1>
                      Was this Third Party Reviewed?
                    </SubHeader1>
                  </Grid>
                  <Grid xs={2}>
                    <BodyText>
                      {survey.product_details.third_party}
                    </BodyText>
                  </Grid>
                </RowElement>
              </Grid>
              <Grid xs={12}>
                <RowElement>
                  <Grid xs={12}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">EU</TableCell>
                            <TableCell align="center">China</TableCell>
                            <TableCell align="center">Japan</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <StyledH5>
                                Date device was submitted in other Regions/jurisdictions
                                (please leave blank if device was not submitted
                                in other geographies)
                              </StyledH5>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  id="EU"
                                  value={filledData.device_submitted_eu}
                                  onChange={(date) =>
                                    handleGeoSubmitEUDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  id="China"
                                  value={filledData.device_submitted_china}
                                  onChange={(date) =>
                                    handleGeoSubmitChinaDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                                    helperText={
                                      params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                                    }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  id="Japan"
                                  value={filledData.device_submitted_japan}
                                  onChange={(date) =>
                                    handleGeoSubmitJapanDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                                    helperText={
                                      params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                                    }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          ></TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <StyledH5>
                                Date device was approved in other geographies
                                (please leave blank if device was not approved
                                in other geographies)
                              </StyledH5>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  value={filledData.device_approved_eu}
                                  onChange={(date) =>
                                    handleGeoApprovedEUDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  value={filledData.device_approved_china}
                                  onChange={(date) =>
                                    handleGeoApprovedChinaDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell align="center">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture={true}
                                  value={filledData.device_approved_japan}
                                  onChange={(date) =>
                                    handleGeoApprovedJapanDateChange(date)
                                  }
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} error={params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY')))}
                            helperText={
                              params.inputProps.value.length>0 && (moment(params.inputProps.value, 'MM/DD/YYYY',true).isValid() === false || moment(params.inputProps.value, 'MM/DD/YYYY').isAfter(moment(new Date(),'MM/DD/YYYY'))) ? 'Please Enter a Valid Date' : ''
                            }/>
                                  )}
                                />
                              </LocalizationProvider>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </RowElement>
              </Grid>

              <Grid xs={12}>
                <RowElement>
                  <Grid xs={1.45}>
                    <StyledH5Light>
                      Other Notes <SmallText>(e.g. What was the statement of basis, How was
                      RWE used?) (Optional)</SmallText>
                    </StyledH5Light>
                  </Grid>
                  <Grid xs={10.55}>
                    <TextField
                      id="other-notes"
                      multiline
                      rows={4}
                      size="small"
                      onBlur={handleOtherNotesChange}
                      defaultValue={filledData.other_notes}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </RowElement>
              </Grid>
            </Grid>
          </StyledSectionDiv>
        </AccordionDetails>
      </Accordion>
      {delIdFunc510k && (
        <IconButton
          style={{
            alignSelf: "start",
            color: themeColors.ui.ui_primary_highlight,
          }}
          onClick={() => {
            delIdFunc510k(survey.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default Accordion510k;
