export const res_data = {
  year: null,
  date_requested: null,
  meeting_held: null,
  written_feedback: null,
  date_filed_accepted: null,
  date_substantive_interaction: null,
  date_approvable_pma: null,
  date_advisory_pma: null,
  NSE_decision_date: null,
  decision: null,
  real_world_evidence_used: null,
  breakthrough_designated_product: null,
  OHT: null,
  patient_generated_data: null,
  clear_statement: null,
  other_notes: null,
  substantive_interaction: null,
  ai_ml_enabled: null,
  device_approved_eu: null,
  device_approved_china: null,
  device_approved_japan: null,
  device_submitted_eu: null,
  device_submitted_china: null,
  device_submitted_japan: null,
  survey_status: "empty",
  submission_put_on_hold: null,
  date_of_submission_hold: null
};

export const res_data_negative_submission = {
  k_number: "",
  survey_status: "empty",
  period: "",
  applicant: "",
  product_type: "",
  third_party: "",
  product_code: "",
  product_name: "",
  active: true,
  date_received: null,
  decision_date: null,
  decision: "",
  trade_name_pma: "",
  date_requested: null,
  meeting_held: null,
  written_feedback: null,
  date_filed_accepted: null,
  date_substantive_interaction: null,
  date_company_feedback: null,
  OHT: "",
  real_world_evidence_used: null,
  patient_generated_data: null,
  breakthrough_designated_product: null,
  clear_statement: null,
  other_notes: "",
  supplement_reason_pma: "",
  NSE_decision_date: null,
  date_advisory_pma: null,
  date_approvable_pma: null,
  substantive_interaction: null,
  ai_ml_enabled: null,
  field_positive: false,
  device_submitted_eu: null,
  device_submitted_china: null,
  device_submitted_japan: null,
  device_approved_eu: null,
  device_approved_china: null,
  device_approved_japan: null,
  product: null,
  product_member: null,
};

export const dropdownOptions = [
  {
    value: null,
    display: "",
  },
  {
    value: "true",
    display: "Yes",
  },
  {
    value: "false",
    display: "No",
  },
];

export const OHTdropdownOptions = [
  {
    value: null,
    display: "",
  },
  {
    value:
      "OHT 1: Office of Ophthalmic, Anesthesia, Respiratory, ENT and Dental Device",
    display:
      "OHT 1: Office of Ophthalmic, Anesthesia, Respiratory, ENT and Dental Device",
  },
  {
    value: "OHT 2: Office of Cardiovascular Devices",
    display: "OHT 2: Office of Cardiovascular Devices",
  },
  {
    value:
      "OHT 3: Office of Gastrorenal, ObGyn, General Hospital, and Urology Devices",
    display:
      "OHT 3: Office of Gastrorenal, ObGyn, General Hospital, and Urology Devices",
  },
  {
    value: "OHT 4: Office of Surgical and Infection Control Devices",
    display: "OHT 4: Office of Surgical and Infection Control Devices",
  },
  {
    value: "OHT 5: Office of Neurological and Physical Medicine Devices",
    display: "OHT 5: Office of Neurological and Physical Medicine Devices",
  },
  {
    value: "OHT 6: Office of Orthopedic Devices",
    display: "OHT 6: Office of Orthopedic Devices",
  },
  {
    value: "OHT 7: Office of In Vitro Diagnostics",
    display: "OHT 7: Office of In Vitro Diagnostics",
  },
  {
    value: "OHT 8: Office of Radiological Health",
    display: "OHT 8: Office of Radiological Health",
  },
];

export const InteractiondropdownOptions = [
  {
    value: null,
    display: "",
  },
  {
    value: "Decision - Approval or Clearance",
    display: "Decision - Approval or Clearance",
  },
  {
    value: "Additional information or Major deficiency letter",
    display: "Additional information or Major deficiency letter",
  },
  {
    value: "Notification about no deficiencies identified",
    display: "Notification about no deficiencies identified",
  },
];
